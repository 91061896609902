import React, { useState } from "react";
import { Carousel, CarouselControl, CarouselItem, CarouselCaption, Row, Col, Button, Container, CardDeck, Card, CardImg, CardBody, CardTitle, CardText, CarouselIndicators } from "reactstrap";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import topCarousel1 from "../images/practice management_v2-02-crop-u474798.png";
import topCarousel2 from "../images/cloud based solutions-01-01-01-crop-u474787.png";
import topCarousel3 from "../images/grow with your firm-01-crop-u474791.png";

import quote from "../images/drive_revenue_svg-06.svg";

import testimonial1 from "../images/0-u409776-fr.jpg";
import testimonial2 from "../images/carna labuschagne.jpg";
import testimonial3 from "../images/sime mlapisane.jpg";
import testimonial4 from "../images/rm tucker attorneys.jpg";

import firm1 from "../images/drive-revnue-firm-logo-1.jpg";
import firm2 from "../images/drive-revnue-firm-logo-2.jpg";
import firm3 from "../images/drive-revnue-firm-logo-3.jpg";
import firm4 from "../images/drive-revnue-firm-logo-4.jpg";
import firm5 from "../images/drive-revnue-firm-logo-5.jpg";

import partner1 from "../images/drive_revenue_partner_logos-16_poster_.png";
import partner2 from "../images/drive_revenue_partner_logos-17_poster_.png";
import partner3 from "../images/acute-logo_white.png";
import partner4 from "../images/outcor-financial-management-800x214_w.png";

import benefit1 from "../images/practice management-01-crop-u394883.png";
import benefit2 from "../images/time-02-crop-u395044.png";
import benefit3 from "../images/always on everywhere-01.png";
import benefit4 from "../images/data protection-01-crop-u413532.png";

import check from "../images/check.svg";
import RequestADemo from "../components/request-a-demo";

const topItems = [
  {
    src: topCarousel1,
    // altText: 'Slide 3',
    header: "Empowering Your Law Firm with Total Practice Management",
    caption: "We combine class-leading billing software and enterprise-grade accounting into a single, powerful business tool.",
    key: "1",
  },
  {
    src: topCarousel2,
    // altText: 'Slide 3',
    header: "Delivering Flexibility and Efficiency Through Our Cloud-based Solutions",
    caption: "Our solutions allow you to work from anywhere, at any time, on any device.",
    key: "2",
  },
  {
    src: topCarousel3,
    // altText: 'Slide 3',
    header: "We Move and Grow with Your Firm and Your Needs",
    caption: "We have solutions for law firms of all sizes, and across all fields of specialization – and are able to build custom tools for your specific practice.",
    key: "3",
  },
];

const TopCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) {
      return;
    }

    const nextIndex = activeIndex === topItems.length - 1 ? 0 : activeIndex + 1;

    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) {
      return;
    }

    const nextIndex = activeIndex === 0 ? topItems.length - 1 : activeIndex - 1;

    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) {
      return;
    }

    setActiveIndex(newIndex);
  };

  const slides = topItems.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        className="d-flex align-items-center top-carousel"
      >
        <div className="carousel-caption d-none d-md-block position-relative text-left mb-4 pb-4" style={{ left: "10%" }}>
          <h1 className="display-3">
            {item.header}
          </h1>
          <p className="pt-4 pb-4 mt-4 mb-4 h5">
            {item.caption}
          </p>
          <RequestADemo>
            {onclick =>
              <Button outline color="light text-uppercase mb-4 rounded-pill pr-5 pl-5 pt-3 pb-3" size="lg" onClick={onclick}>
                Request a Demo
              </Button>
            }
          </RequestADemo>
        </div>

        <img src={item.src} style={{ height: "70vh" }} className="" />

      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="carousel-fade top-carousel"
    >
      {slides}

      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

const testimonialItems = [
  {
    src: testimonial1,
    person: "Albert A. Marais",
    title: "CEO & Co Founder at Albert A. Marais Attorneys",
    testimonial: "I have no hesitation in recommending Drive Practice to any and every legal practice, irrespective of size and/or field of specialisation.",
    href: "/blog/2018-11-01-drive-practice-works-albert-a-marais",
    key: "1",
  },
  {
    src: testimonial2,
    person: "CARNA LABUSCHAGNE",
    title: "Attorney & Owner of CL Attorneys",
    testimonial: "One of the amazing perks of being a client of Drive, is that you have support at your beck and call, no matter how big or small the issue is...",
    href: "/blog/2018-07-02-my-accountant-loves-drive-carna-labuschagne",
    key: "2",
  },
  {
    src: testimonial3,
    person: "Sime Mlapisane",
    title: "Attorney of the High Court at Mlapisane Attorneys",
    testimonial: "The availability of the support staff has been invaluable, as well as their readiness to personally meet to assist. I applaud their world class service.",
    href: "/blog/2018-05-15-world-class-service-mlapisane-attorneys",
    key: "3",
  },
  {
    src: testimonial4,
    person: "Ryan Tucker",
    title: "RM Tucker Attorneys",
    testimonial: "I am amazed at how powerful the application is and how much time it is saving me, freeing me up to bill more hours.",
    href: "/blog/2018-08-16-i-am-amazed-at-how-powerful-the-application-is-ryan-tucker",
    key: "4",
  },
];

const TestimonialCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) {
      return;
    }

    const nextIndex = activeIndex === testimonialItems.length - 1 ? 0 : activeIndex + 1;

    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) {
      return;
    }

    const nextIndex = activeIndex === 0 ? testimonialItems.length - 1 : activeIndex - 1;

    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) {
      return;
    }

    setActiveIndex(newIndex);
  };

  const slides = testimonialItems.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="carousel-caption position-relative text-left mb-4 p-4 border shadow text-dark d-flex flex-column">
          <div className="d-flex align-items-start pl-5 ml-5 pt-5 mt-5">
            <img src={quote} className="pr-3" />
            <h1 className="h2">
              {item.person}
              <br />
              <small>
                {item.title}
              </small>
            </h1>
          </div>
          <p className="p-4 mt-4 mb-4 h5 h4 text-primary text-center">
            {item.testimonial}
          </p>

          <Button color="primary" size="lg" className="rounded-pill mt-5 mb-5 text-uppercase align-self-center" tag={Link} to={item.href}>
            Read full story
          </Button>

          <img src={item.src} className="rounded-circle" style={{ position: "absolute", left: "6rem", top: -50 }} />
        </div>


      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="carousel-testimonials"
      interval={false}
    >
      <CarouselIndicators items={testimonialItems} activeIndex={activeIndex} onClickHandler={goToIndex} />

      {slides}

      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Practice Management - Perfected" />

      <TopCarousel />

      <Container fluid>
        <Row style={{ background: "#151445" }} className="pt-5 pb-5 align-items-center justify-content-center">
          <Col md={2}>
            <h2 className="text-white h5 text-uppercase">
              Firms who have put their trust in us
          </h2>
          </Col>
          <Col style={{ flex: 0 }}>
            <a href="https://www.facebook.com/alberta.marais.attorneys.7">
              <img src={firm1} />
            </a>
          </Col>
          <Col style={{ flex: 0 }}>
            <a href="http://www.warrenderlaw.co.za/">
              <img src={firm2} />
            </a>
          </Col>
          <Col style={{ flex: 0 }}>
            <a href="http://clalaw.co.za">
              <img src={firm3} />
            </a>
          </Col>
          <Col style={{ flex: 0 }}>
            <a href="https://www.linkedin.com/in/sime-mlapisane-32929714a/">
              <img src={firm4} />
            </a>
          </Col>
          <Col style={{ flex: 0 }}>
            <a href="https://www.rmtucker.co.za/">
              <img src={firm5} />
            </a>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="pt-5 mt-5 pb-5 mb-5 justify-content-center">
          <Col md={6} className="text-center">
            <h1 className="text-primary display-3">
              Save Time and Drive Revenue in Your Law Firm
          </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-center">
          <Col md={9}>
            <CardDeck>
              <Card inverse style={{ background: "linear-gradient(to bottom, #1C94FC, #3750C2 100%)" }} className="border-none d-flex flex-column justify-content-center">
                <div className="d-flex flex-column justify-content-center" style={{ height: 290 }}>
                  <CardImg src={benefit1} />
                </div>
                <CardBody style={{ flex: "0 0 auto" }}>
                  <CardTitle tag="h4">
                    Total Practice Management
                </CardTitle>
                  <CardText>
                    Combine class-leading billing software and enterprise-grade accounting into a single, powerful business tool.
                </CardText>
                </CardBody>
              </Card>

              <Card inverse style={{ background: "linear-gradient(to bottom, #1C94FC, #3750C2 100%)" }} className="border-none d-flex flex-column justify-content-center">
                <div className="d-flex flex-column justify-content-center" style={{ height: 290 }}>
                  <CardImg src={benefit2} />
                </div>
                <CardBody style={{ flex: "0 0 auto" }}>
                  <CardTitle tag="h4">
                    Less Time <br />on Admin
                </CardTitle>
                  <CardText>
                    More Time on Driving Your Revenue. All the power of enterprise level accounting at a fraction of the cost.
                </CardText>
                </CardBody>
              </Card>

              <Card inverse style={{ background: "linear-gradient(to bottom, #1C94FC, #3750C2 100%)" }} className="border-none d-flex flex-column justify-content-center">
                <div className="d-flex flex-column justify-content-center" style={{ height: 290 }}>
                  <CardImg src={benefit3} />
                </div>
                <CardBody style={{ flex: "0 0 auto" }}>
                  <CardTitle tag="h4">
                    Always on, Everywhere
                </CardTitle>
                  <CardText>
                    Our solutions are cloud based, freeing you to work from anywhere, at any time, on any device.
                </CardText>
                </CardBody>
              </Card>

              <Card inverse style={{ background: "linear-gradient(to bottom, #1C94FC, #3750C2 100%)" }} className="border-none d-flex flex-column justify-content-center">
                <div className="d-flex flex-column justify-content-center" style={{ height: 290 }}>
                  <CardImg src={benefit4} />
                </div>
                <CardBody style={{ flex: "0 0 auto" }}>
                  <CardTitle tag="h4">
                    Your Precious Data, Secured
                </CardTitle>
                  <CardText>
                    Our Microsoft Azure cloud-based security protects your data with ultra-secure 256 bit encryption.
                </CardText>
                </CardBody>
              </Card>
            </CardDeck>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-center" style={{ background: "#151445", marginTop: -380 }}>
          <Col md={9} style={{ marginTop: 540, marginBottom: 150 }}>
            <h1 className="display-3 text-secondary text-center pb-5 mb-5">
              How can Drive Revenue Upgrade Your Practice?
          </h1>

            <Row className="text-white h5">
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              Designed by business system experts and lawyers, Drive Revenue’s solutions have
              been engineered for law firms – regardless of their size or field of specialization.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              Our Solutions are much more than billing software – allowing you to capture billable
              hours as well as generate valuable business insights – empowering you to unlock the full earning potential of your practice.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              Drive Revenue’s safe and secure cloud platform brings you cost savings, flexibility
              and scalability while ensuring compliance – delivering complete peace of mind.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              NO upfront costs for special hardware – Our Solutions are accessible from any device with access to the Internet.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              Drive Revenue is intuitive and easy-to-use, connecting you with your practice management
              and accounting systems in real-time – wherever you are and on any device.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              NO upfront software license fees or hardware upgrade, which means our solutions will save you time and money.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              Our Solutions bring your legal practice together in one place - Allowing you to manage your
              clients and matters, reports, monthly and ad-hoc billings.
            </Col>
              <Col md={6} className="p-5 d-flex align-items-start">
                <img src={check} className="pr-3" />
              Take advantage of our competitive rates with a monthly, quarterly or annual subscription – with
              the freedom to add more users as your practice grows.
            </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-center text-center mt-5 pt-5">
          <Col md={6}>
            <h1 className="display-4 text-primary mt-5 pt-5">
              Clients’ Testimonials
          </h1>
            <p className="lead text-secondary pt-5">
              A few words from our clients
          </p>
          </Col>
        </Row>
      </Container>

      <TestimonialCarousel />

      <Container fluid>
        <Row className="justify-content-center text-center" style={{ background: "#3750C2", marginTop: -140, paddingTop: 320, paddingBottom: 200 }}>
          <Col xs={12}>
            <h1 className="display-3 text-secondary mb-5">
              Partner Firms &amp; Companies
          </h1>
            <p className="lead pb-5 mb-5">
              We Drive Revenue through Partnerships
          </p>
          </Col>
          <Col xs={12} className="partners">
            <a href="https://asilaw.co.za">
              <img src={partner1} />
            </a>

            <img src={partner2} />

            <a href="http://acuteaccountants.co.za">
              <img src={partner3} />
            </a>

            <a href="http://www.outcor.co.za">
              <img src={partner4} />
            </a>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-center text-center mt-5 pt-5">
          <Col xs={8}>
            <p className="display-3 text-secondary">
              Contact us if your practice is ready for the next level
          </p>
            <p className="lead pt-4">
              Contact us today or request a demo.
          </p>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-center text-center mt-5 pt-5 pb-5 mb-5">
          <Col xs={6}>
            <Col>
              <RequestADemo>
                {onclick =>
                  <Button className="rounded-pill text-uppercase m-3 pl-5 pr-5 pt-3 pb-3" color="primary" size="lg" onClick={onclick}>
                    Request a Demo
                  </Button>
                }
              </RequestADemo>
              <Button className="rounded-pill text-uppercase m-3 pl-5 pr-5 pt-3 pb-3" color="secondary" size="lg" tag={Link} to="/our-solutions">
                Find Out More
              </Button>
            </Col>
          </Col>
        </Row>
      </Container>
    </Layout >
  );
}

export default IndexPage;
